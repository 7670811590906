
import { defineComponent, reactive, ref } from 'vue'
// import { useNotification } from '@/composables'

export default defineComponent({
  components: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    const displayValue = ref('')
    const typeValue = ref('')

    // const { error, success } = useNotification()

    const onSubmit = () => {
      context.emit(
        'callbackEditOptionName',
        typeValue.value,
        displayValue.value
      )
      close()
    }

    const toggle = (value: string, type: string) => {
      displayValue.value = value
      typeValue.value = type
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      dialogVisible.value = false
      // window.location.reload()
    }

    return {
      toggle,
      close,
      onSubmit,
      displayValue,
      typeValue,
      dialogVisible,
    }
  },
})
