
import { stringRules } from '@/utils/formRules'
import { defineComponent, reactive, ref, watch } from 'vue'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useNotification } from '@/composables'
import { SHIPPING_METHOD_DROPSHIP } from '@/utils/constants'
import { Delete } from '@element-plus/icons'

export default defineComponent({
  components: { Delete },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const dialogVisible = ref(false)
    const variantId = ref('')
    const catalogId = ref('')
    const countryList = ref<any[]>([...props.countries])
    const dataShipping = ref<any[]>([])

    const { error, success } = useNotification()
    const rules = {
      variantCode: stringRules('variant code', 2, 128),
      // shipfirstItem: required('First'),
      // shipAdditionalItem: required('Additional'),
    }

    const { updateVariant, findOne } = useCatalogVariantRepositories()
    let state = reactive({
      formVariant: {
        catalogVariantCode: '',
        cost: '',
        shippingInfos: [
          {
            countryCode: '',
            countryName: '',
            method: '',
            shipfirstItem: 0,
            shipAdditionalItem: 0,
          },
        ],
      },
      otherCountries: [],
    })
    const loading = ref(false)

    const form = reactive({
      countryCode: 'US',
      countryName: 'United States',
      method: 'standard',
      shipfirstItem: 0,
      shipAdditionalItem: 0,
    })

    const onClickShippingInfo = (item: any) => {
      Object.assign(form, { ...item })
    }

    const checkExistedShippingInfo = (code: string, method: string) => {
      const findExisted = state.formVariant.shippingInfos.findIndex(
        item => item.countryCode === code && item.method === method
      )

      if (findExisted > -1) {
        state.formVariant.shippingInfos.splice(findExisted, 1) // 2nd parameter means remove one item only
      }
    }

    const onClear = () => {
      state.formVariant.shippingInfos = []
    }

    const onAddDataOthers = () => {
      if (state.otherCountries) {
        const findIndexAll = state.otherCountries.findIndex(
          item => item === 'ALL-COUNTRIES'
        )
        if (findIndexAll > -1) {
          for (const country of countryList.value) {
            checkExistedShippingInfo(country.code, form.method)
            const shippingInfo = {
              countryCode: country.code,
              countryName: country.name,
              method: form.method,
              shipfirstItem: form.shipfirstItem,
              shipAdditionalItem: form.shipAdditionalItem,
            }
            state.formVariant.shippingInfos.push({ ...shippingInfo })
          }
        } else {
          for (const countryCode of state.otherCountries) {
            const findCountryName = countryList.value.filter(item => {
              return item.code === countryCode
            })
            if (findCountryName) {
              checkExistedShippingInfo(countryCode, form.method)
              const shippingInfo = {
                countryCode: countryCode,
                countryName: findCountryName[0].name,
                method: form.method,
                shipfirstItem: form.shipfirstItem,
                shipAdditionalItem: form.shipAdditionalItem,
              }
              state.formVariant.shippingInfos.push({ ...shippingInfo })
            }
          }
        }
      }
    }

    const onChangeCountry = () => {
      const findCountryName = countryList.value.filter(item => {
        return item.code === form.countryCode
      })
      if (findCountryName) {
        form.countryName = findCountryName[0].name
      }
    }
    const changeDescription = (html: string, dataSet: any) => {
      dataSet.description = html
    }

    const fetchRecord = async () => {
      loading.value = true
      let { data, status } = await findOne(variantId.value)
      if (status === 200) {
        state.formVariant = data as any
      }
      loading.value = false
    }

    const onAddData = () => {
      const findExisted = state.formVariant.shippingInfos.findIndex(
        item =>
          item.countryCode === form.countryCode && item.method === form.method
      )

      if (findExisted > -1) {
        state.formVariant.shippingInfos.splice(findExisted, 1) // 2nd parameter means remove one item only
      }

      state.formVariant.shippingInfos.push({ ...form })
    }

    const deleteShippingInfo = (row: any) => {
      const findExisted = state.formVariant.shippingInfos.findIndex(
        item =>
          item.countryCode === row.countryCode && item.method === row.method
      )

      if (findExisted > -1) {
        state.formVariant.shippingInfos.splice(findExisted, 1)
      }
    }

    const onSubmit = async () => {
      const { status, data } = await updateVariant(state.formVariant)
      if (status === 200) {
        close()
        success('Update success')
      } else {
        error(data.message)
      }
    }

    const toggle = (id: string, cid: any) => {
      variantId.value = id
      catalogId.value = cid
      dialogVisible.value = !dialogVisible.value
      fetchRecord()
    }

    const close = () => {
      dialogVisible.value = false
      // window.location.reload()
    }

    return {
      state,
      onAddData,
      dataShipping,
      rules,
      dialogVisible,
      loading,
      toggle,
      close,
      onSubmit,
      changeDescription,
      SHIPPING_METHOD_DROPSHIP,
      countryList,
      form,
      onChangeCountry,
      onClickShippingInfo,
      onAddDataOthers,
      deleteShippingInfo,
      checkExistedShippingInfo,
      onClear,
    }
  },
})
