
import { stringRules } from '@/utils/formRules'
import { defineComponent, reactive, ref } from 'vue'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useNotification } from '@/composables'
import { useResource } from '@/services'
import { WEIGHT_UNIT } from '@/utils/constants'
import { formatterDollars } from '@/utils/formatter'
import UploadImage from '@/components/common/UploadImage.vue'

export default defineComponent({
  components: {
    Editor,
    UploadImage,
  },
  setup(props, context) {
    const dialogVisible = ref(false)
    const catalogVariantForm = ref()
    const variantId = ref('')
    const catalogId = ref('')

    const { updateVariant, findOne } = useCatalogVariantRepositories()
    let state = reactive({
      formVariant: {
        catalogVariantCode: '',
        color: {
          name: '',
          code: '',
          value: '',
        },
        size: {
          name: '',
          code: '',
          value: '',
        },
        designs: '',
        status: '',
        description: '',
        cost: 0,
        mockupGallery: [],
        supplierMinPrice: null,
        supplierMaxPrice: null,
        supplierQuantity: null,
      },
      designs: ref<any[]>([]),
    })

    const loading = ref(false)
    const { error, success } = useNotification()
    const rulesVariant = {
      catalogVariantCode: stringRules('code', 1, 128),
      // cost: stringRules('cost', 1, 128),
      // weight: stringRules('weight', 1, 128),
      // weightUnit: stringRules('weightUnit', 1, 128),
    }
    const callbackMockupVariant = (setData: any, files: string[]) => {
      setData.mockupGallery = files
    }

    const onSubmit = () => {
      catalogVariantForm.value?.validate(async (isValid: boolean) => {
        if (isValid) {
          const { status, data } = await updateVariant(state.formVariant)
          if (status === 200) {
            context.emit('onAfterUpdateVariant')
            close()
            success('Update success')
          } else {
            error(data.message)
          }
        }
      })
    }

    const changeDescription = (html: string, dataSet: any) => {
      dataSet.description = html
    }

    const fetchRecord = async () => {
      loading.value = true
      let { data, status } = await findOne(variantId.value)
      if (status === 200) {
        console.log('state of variant', data)
        state.formVariant = data as any
      }
      loading.value = false
    }

    const toggle = (id: string, cid: any) => {
      variantId.value = id
      catalogId.value = cid
      dialogVisible.value = !dialogVisible.value
      fetchRecord()
    }

    const close = () => {
      catalogVariantForm.value?.resetFields()
      dialogVisible.value = false
    }

    const closeVariant = () => {
      dialogVisible.value = false
    }

    return {
      state,
      rulesVariant,
      dialogVisible,
      catalogVariantForm,
      loading,
      toggle,
      closeVariant,
      close,
      onSubmit,
      changeDescription,
      WEIGHT_UNIT,
      callbackMockupVariant,
      formatterDollars,
    }
  },
})
