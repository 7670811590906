
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  components: {},
  props: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    let state = reactive({
      cost: null,
    })

    const onSubmit = () => {
      context.emit('callbackCostForUpdateSelecteds', state.cost)
      close()
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      dialogVisible.value = false
    }

    return {
      state,
      dialogVisible,
      onSubmit,
      toggle,
      close,
    }
  },
})
